.combobox {
    height: 40px;
    width: 400px;
    border: 1px solid gray;
    padding: 8px;
}

@media screen and (max-width: 460px) {
    .combobox {
      width: 240px;
    }
  }
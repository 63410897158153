.paginationContainer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 280px !important;
    list-style: none !important;
}

.activePage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background: var(--chakra-colors-teal-700);
    color: white;
    border-radius: 50%;
}

.page {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
}
.basic-multi-select {
    z-index: 5;
}

.basic-multi-select-items {
    .select__control {
        z-index: 2;
    }
    .select__menu {
        z-index: 3;
    }
}

.custom-service-select {
    width: 100%;
    font-size: 14px;
}
